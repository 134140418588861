import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import Today from "@material-ui/icons/Restore";
import FilterList from "@material-ui/icons/FilterList";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ReactSelect from "react-select";
import Badge from "@material-ui/core/Badge";
import { DatePicker } from "@material-ui/pickers";

import moment from "moment";
import "moment/locale/es";

import { withTranslation, getLanguage } from "react-multi-lang";

import AuthService from "../components/auth/AuthService";
import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const Auth = new AuthService();

function Select({ defaultValue, options, ...otherProps }) {
    return (
        <ReactSelect
            defaultValue={
                defaultValue && {
                    value: defaultValue,
                    label: options.find(o => o.value === defaultValue).label
                }
            }
            options={options}
            {...otherProps}
        />
    );
}

const localeMap = {
    en: "en",
    es: "es"
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    date: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    headerIcon: {
        marginRight: 5
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    datePicker: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        width: 90
    },
    secondaryBar: {
        backgroundColor: "rgb(232, 232, 232)"
    }
});
class CheckoutsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            checkoutsList: [],
            initialcheckoutsList: [],
            filters: {
                viewDate: moment().format("YYYY-MM-DD"),
                viewProperty: 0,
                viewBuilding: 0
            },
            drawerOpen: false,
            filtersCount: 0,
            propertiesList: [],
            buildingsList: [],
            currentLang: getLanguage()
        };

        moment.locale(this.state.currentLang);

        var decoded = Auth.getProfile();

        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userName = decoded.userName;

        this.logout = this.logout.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.fetchCheckouts = this.fetchCheckouts.bind(this);
        this.goToToday = this.goToToday.bind(this);
        this.handleDateNext = this.handleDateNext.bind(this);
        this.handleDatePrev = this.handleDatePrev.bind(this);

        var coViewConfig = JSON.parse(localStorage.getItem("coViewConfig"));
        if (coViewConfig) {
            Object.keys(coViewConfig).forEach(key => {
                this.state.filters[key] = coViewConfig[key];
            });
        }
    }

    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    toggleDrawer = open => () => {
        this.setState({
            drawerOpen: open
        });

        if (!open) {
            this.handleMenuClose();
        }
    };

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDateNext() {
        var next = moment(this.state.filters.viewDate).add(1, "days").format("YYYY-MM-DD");

        this.setState({ isLoading: true, filters: { ...this.state.filters, viewDate: next } }, () => {
            localStorage.setItem("coViewConfig", JSON.stringify(this.state.filters));
            this.refetchCheckouts();
        });
    }

    handleDatePrev() {
        var prev = moment(this.state.filters.viewDate).subtract(1, "days").format("YYYY-MM-DD");

        this.setState({ isLoading: true, filters: { ...this.state.filters, viewDate: prev } }, () => {
            localStorage.setItem("coViewConfig", JSON.stringify(this.state.filters));
            this.refetchCheckouts();
        });
    }

    async componentDidMount() {
        let properties = await this.fetchProperties();
        let buildings = await this.fetchBuildings();
        let checkouts = await this.fetchCheckouts();

        this.setState({
            checkoutsList: checkouts.data,
            initialcheckoutsList: checkouts.data,
            buildingsList: buildings,
            propertiesList: properties,
            filtersCount: this.countFilters(),
            isLoading: false
        });
    }

    async refetchCheckouts() {
        let checkouts = await this.fetchCheckouts();

        this.setState({
            checkoutsList: checkouts.data,
            initialcheckoutsList: checkouts.data,
            filtersCount: this.countFilters(),
            isLoading: false
        });
    }

    async fetchCheckouts() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .post("co/checkouts", self.state.filters)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchProperties() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ca/properties")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchBuildings() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("buildings")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    countFilters() {
        let count = 0;
        Object.keys(this.state.filters).forEach(key => {
            if (key !== "viewDate") {
                if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
                    count++;
                }
            }
        });

        return count;
    }

    handleFilterChange = date => {
        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    viewDate: date ? moment(date).format("YYYY-MM-DD") : null
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("coViewConfig", JSON.stringify(this.state.filters));
                this.refetchCheckouts();
            }
        );
    };

    handleSelect = (val, event) => {
        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    [event.name]: val ? val.value : null
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("coViewConfig", JSON.stringify(this.state.filters));
                this.handleMenuClose();
                this.refetchCheckouts();
            }
        );
    };

    goToToday() {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewDate: moment().format("YYYY-MM-DD")
                }
            },
            () => {
                localStorage.setItem("coViewConfig", JSON.stringify(this.state.filters));
                this.refetchCheckouts();
            }
        );
    }

    render() {
        const { anchorEl } = this.state;
        const { classes, t } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const locale = localeMap[this.state.currentLang];

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem className={classes.sectionMobile} onClick={this.toggleDrawer(true)}>
                    <p>
                        {t("nav.Filter")} {this.state.filtersCount > 0 ? "(" + this.state.filtersCount + ")" : null}{" "}
                    </p>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        this.props.history.push("/settings/");
                    }}
                >
                    <p>{t("nav.Settings")}</p>
                </MenuItem>

                <MenuItem onClick={this.logout}>
                    <p>{t("nav.Logout", { param: this.state.userName })}</p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <FormatListBulleted className={classes.headerIcon} />
                        <Typography variant="h6" color="inherit" noWrap>
                            checkoutApp
                        </Typography>

                        <div className={classes.grow} />
                        <IconButton color="inherit" onClick={this.goToToday} aria-label={t("misc.Today")}>
                            <Today />
                        </IconButton>
                        <div className={classes.sectionDesktop}>
                            <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.toggleDrawer(true)} color="inherit">
                                <Badge badgeContent={this.state.filtersCount} color="secondary">
                                    <FilterList />
                                </Badge>
                            </IconButton>
                        </div>
                        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.handleMenuOpen} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>

                    <Toolbar disableGutters={true} className={classes.secondaryBar}>
                        <IconButton onClick={this.handleDatePrev} className={classes.dateButton} style={{ marginLeft: 5 }} aria-label={t("misc.Previous")}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <div className={classes.datePicker}>
                            <DatePicker
                                InputProps={{
                                    disableUnderline: true
                                }}
                                locale={locale}
                                fullWidth={true}
                                format="DD/MM/YYYY"
                                autoOk={true}
                                value={this.state.filters.viewDate}
                                onChange={this.handleDateChange}
                            />
                        </div>

                        <IconButton onClick={this.handleDateNext} className={classes.dateButton} style={{ marginRight: 5 }} aria-label={t("misc.Next")}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {renderMenu}

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <List>
                    {!this.state.isLoading &&
                        this.state.checkoutsList.map((checkout, index) => {
                            return (
                                <div key={index}>
                                    <ListItem
                                        key={checkout.property.propertyId}
                                        button
                                        component="a"
                                        onClick={() => {
                                            this.props.history.push("/checkout/" + checkout.property.propertyId + "");
                                        }}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    type="h6"
                                                    style={
                                                        checkout.checkoutDone === 1
                                                            ? {
                                                                  textDecoration: "line-through",
                                                                  color: "grey"
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {checkout.property.propertyName}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider className={classes.divider} />
                                </div>
                            );
                        })}
                </List>
                <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
                    <div tabIndex={0} role="button" className={classes.drawer}>
                        <div className={classes.drawerInput}>
                            <Typography variant="h5" color="inherit">
                                {t("list.Filters")}
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                {t("list.Property")}
                            </Typography>

                            <Select
                                isClearable
                                name="viewProperty"
                                options={this.state.propertiesList.map(option => ({
                                    value: option.propertyId,
                                    label: option.propertyName
                                }))}
                                defaultValue={this.state.filters.viewProperty}
                                classNamePrefix="react-select"
                                onChange={this.handleSelect}
                            />
                        </div>

                        {this.state.accessLevel > 2 && (
                            <div className={classes.drawerInput}>
                                <Typography variant="h6" color="inherit">
                                    {t("list.Building")}
                                </Typography>

                                <Select
                                    isClearable
                                    name="viewBuilding"
                                    options={this.state.buildingsList.map(option => ({
                                        value: option.buildingId,
                                        label: option.buildingName
                                    }))}
                                    defaultValue={this.state.filters.viewBuilding}
                                    classNamePrefix="react-select"
                                    onChange={this.handleSelect}
                                />
                            </div>
                        )}
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

CheckoutsList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(CheckoutsList)));

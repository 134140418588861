import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Report from "@material-ui/icons/Build";
import List from "@material-ui/icons/ListAlt";
import Task from "@material-ui/icons/NoteAdd";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import MobileStepper from "@material-ui/core/MobileStepper";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import withAuth from "../components/auth/withAuth";
import AuthService from "../components/auth/AuthService";

import validate from "./validate";

import { withTranslation } from "react-multi-lang";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import ImageCompressor from "uppy-plugin-image-compressor";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

import moment from "moment";

import { load as loadCheckout } from "../components/reducers/checkout";

import { connect } from "react-redux";

import { Field, reduxForm, FieldArray, change, formValueSelector } from "redux-form";

import fetchClient from "../components/utils/fetchClient";

const Auth = new AuthService();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

class renderUploader extends React.Component {
    UNSAFE_componentWillMount() {
        this.picuploader = new Uppy({ id: "image", autoProceed: true })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "checkout",
                                upload_type: "checkout",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.props.change(`sections.${this.props.parentIndex}.questions.${this.props.index}.questionContent`, [...this.props.input.value, resp.uploadURL]);
            });
    }

    componentWillUnmount() {
        this.picuploader.close();
    }

    render() {
        const { picuploader } = this;
        const { label, t, required } = this.props;

        return (
            <div>
                <Typography variant="h6" color="inherit">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${label} ${required ? "*" : ""}`
                        }}
                    ></span>
                </Typography>

                <DragDrop
                    uppy={picuploader}
                    locale={{
                        strings: {
                            dropHereOr: t("checkout.Upload") + " %{browse}",
                            browse: t("checkout.Browse")
                        }
                    }}
                />
                <StatusBar uppy={picuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
                {this.props.input.value &&
                    this.props.input.value.map((image, index) => {
                        return <img key={index} height="50" width="50" src={image} alt={image.split(".com/").pop()} className="uploaded-image" />;
                    })}
            </div>
        );
    }
}
class renderUnlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            front_loading: false,
            apt_loading: false,
            submitEnabled: true,
            pressCount: { 1: { count: 0 }, 2: { count: 0 } }
        };

        this.handleOpenDoor = this.handleOpenDoor.bind(this);
    }

    handleOpenDoor(id, type) {
        var self = this;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (self.state.pressCount[type].count === 1) {
            self.setState({
                submitEnabled: false,
                front_loading: type === 2 ? true : false,
                apt_loading: type === 1 ? true : false
            });
            fetchClient
                .post("ca/smartlock/" + id, { doortype: type })
                .then(function (response) {
                    self.setState({
                        front_loading: false,
                        apt_loading: false,
                        submitEnabled: true,
                        pressCount: { 1: { count: 0 }, 2: { count: 0 } }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            const newpressCount = self.state.pressCount;
            newpressCount[type].count = 1;

            self.setState({
                pressCount: newpressCount
            });
        }

        this.timer = setTimeout(() => {
            self.setState({
                pressCount: { 1: { count: 0 }, 2: { count: 0 } }
            });
        }, 3000);
    }

    render() {
        const { label, t, propertyId, hasSmartLock, classes, required } = this.props;

        return (
            <div>
                <Typography variant="h6" color="inherit">
                    {t("checkout.SmartLock")} {required ? "*" : ""}
                </Typography>
                <div className={classes.lock}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={
                            this.state.pressCount[2].count === 1 && !this.state.front_loading
                                ? {
                                      backgroundColor: "red"
                                  }
                                : null
                        }
                        disabled={!this.state.submitEnabled || hasSmartLock === 1}
                        onClick={() => {
                            this.handleOpenDoor(propertyId, 2);
                        }}
                    >
                        {this.state.front_loading ? <CircularProgress size={24} /> : this.state.pressCount[2].count === 1 ? t("checkout.PressAgain") : t("checkout.FrontDoor")}
                    </Button>

                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={
                            this.state.pressCount[1].count === 1 && !this.state.front_loading
                                ? {
                                      backgroundColor: "red"
                                  }
                                : null
                        }
                        disabled={!this.state.submitEnabled || hasSmartLock === 3}
                        onClick={() => {
                            this.handleOpenDoor(propertyId, 1);
                        }}
                    >
                        {this.state.apt_loading ? <CircularProgress size={24} /> : this.state.pressCount[1].count === 1 ? t("checkout.PressAgain") : t("checkout.AptDoor")}
                    </Button>

                    <br />
                    <FormControlLabel
                        control={<Checkbox checked={this.props.input.value ? true : false} onChange={this.props.input.onChange} />}
                        label={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `${label} ${required ? "*" : ""}`
                                }}
                            ></span>
                        }
                    />
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 10
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        padding: theme.spacing(),
        margin: theme.spacing()
    },
    flex: {
        flex: 1
    },
    appBar: {
        position: "relative"
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300
    },
    paper: {
        marginBottom: 50
    },
    buttonSaving: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    button: {
        marginTop: 10
    }
});

const renderTextField = ({ label, input, type, required, meta: { touched, invalid, error } }) => (
    <div>
        <Typography variant="h6" color="inherit">
            <span
                dangerouslySetInnerHTML={{
                    __html: `${label} ${required ? "*" : ""}`
                }}
            ></span>
        </Typography>
        <TextField style={{ paddingTop: 10, paddingBottom: 10 }} type={type} placeholder={label} error={touched && invalid} fullWidth value={input.value} helperText={touched && error} {...input} />
    </div>
);

const renderCheckbox = ({ input, label, required }) => (
    <div>
        <FormControlLabel
            control={<Checkbox checked={input.value ? true : false} onChange={input.onChange} />}
            label={
                <span
                    dangerouslySetInnerHTML={{
                        __html: `${label} ${required ? "*" : ""}`
                    }}
                ></span>
            }
        />
    </div>
);

const renderRadio = ({ input, label, classes, t, required }) => (
    <div>
        <Typography variant="h6" color="inherit">
            <span
                dangerouslySetInnerHTML={{
                    __html: `${label} ${required ? "*" : ""}`
                }}
            ></span>
        </Typography>
        <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup value={input.value} onChange={input.onChange}>
                <FormControlLabel value={"2"} control={<Radio />} label={t("checkout.Yes")} />
                <FormControlLabel value={"1"} control={<Radio />} label={t("checkout.No")} />
                <FormControlLabel value={"0"} control={<Radio />} label={t("checkout.NoResponse")} />
            </RadioGroup>
        </FormControl>
    </div>
);

const renderSections = ({ fields, change, classes, t, activeStep, propertyId, hasSmartLock, openReference }) => (
    <div>
        {fields.length ? (
            fields.map((section, index) => {
                let current = fields.get(index);

                return (
                    <div key={index}>
                        {activeStep === index && (
                            <div>
                                <Typography variant="h5" color="inherit" className={classes.grow}>
                                    {current["name"]}
                                </Typography>

                                {current["section_files"].map((file, index) => {
                                    return file.type === "pdf" ? (
                                        <span key={index} onClick={() => openReference(file)}>
                                            <svg
                                                width={50}
                                                height={50}
                                                viewBox="0 0 512 512"
                                                style={{
                                                    cursor: "pointer",
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                <path d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z" fill="#e2e5e7" />
                                                <path d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z" fill="#b0b7bd" />
                                                <path fill="#cad1d8" d="M480 224l-96-96h96z" />
                                                <path d="M416 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z" fill="#f15642" />
                                                <g fill="#fff">
                                                    <path d="M101.744 303.152c0-4.224 3.328-8.832 8.688-8.832h29.552c16.64 0 31.616 11.136 31.616 32.48 0 20.224-14.976 31.488-31.616 31.488h-21.36v16.896c0 5.632-3.584 8.816-8.192 8.816-4.224 0-8.688-3.184-8.688-8.816v-72.032zm16.88 7.28v31.872h21.36c8.576 0 15.36-7.568 15.36-15.504 0-8.944-6.784-16.368-15.36-16.368h-21.36zM196.656 384c-4.224 0-8.832-2.304-8.832-7.92v-72.672c0-4.592 4.608-7.936 8.832-7.936h29.296c58.464 0 57.184 88.528 1.152 88.528h-30.448zm8.064-72.912V368.4h21.232c34.544 0 36.08-57.312 0-57.312H204.72zM303.872 312.112v20.336h32.624c4.608 0 9.216 4.608 9.216 9.072 0 4.224-4.608 7.68-9.216 7.68h-32.624v26.864c0 4.48-3.184 7.92-7.664 7.92-5.632 0-9.072-3.44-9.072-7.92v-72.672c0-4.592 3.456-7.936 9.072-7.936h44.912c5.632 0 8.96 3.344 8.96 7.936 0 4.096-3.328 8.704-8.96 8.704h-37.248v.016z" />
                                                </g>
                                                <path d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z" fill="#cad1d8" />
                                            </svg>
                                        </span>
                                    ) : (
                                        <img
                                            style={{
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                            height="50"
                                            width="50"
                                            className="uploaded-image"
                                            key={index}
                                            src={file.src}
                                            alt={file.src.split("/").pop()}
                                            onClick={() => openReference(file)}
                                        />
                                    );
                                })}

                                <div
                                    style={{ paddingBottom: 15 }}
                                    dangerouslySetInnerHTML={{
                                        __html: current["standards"]
                                    }}
                                />

                                {current["files"].map((file, index) => {
                                    return file.type === "pdf" ? (
                                        <span key={index} onClick={() => openReference(file)}>
                                            <svg
                                                width={50}
                                                height={50}
                                                viewBox="0 0 512 512"
                                                style={{
                                                    cursor: "pointer",
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                <path d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z" fill="#e2e5e7" />
                                                <path d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z" fill="#b0b7bd" />
                                                <path fill="#cad1d8" d="M480 224l-96-96h96z" />
                                                <path d="M416 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z" fill="#f15642" />
                                                <g fill="#fff">
                                                    <path d="M101.744 303.152c0-4.224 3.328-8.832 8.688-8.832h29.552c16.64 0 31.616 11.136 31.616 32.48 0 20.224-14.976 31.488-31.616 31.488h-21.36v16.896c0 5.632-3.584 8.816-8.192 8.816-4.224 0-8.688-3.184-8.688-8.816v-72.032zm16.88 7.28v31.872h21.36c8.576 0 15.36-7.568 15.36-15.504 0-8.944-6.784-16.368-15.36-16.368h-21.36zM196.656 384c-4.224 0-8.832-2.304-8.832-7.92v-72.672c0-4.592 4.608-7.936 8.832-7.936h29.296c58.464 0 57.184 88.528 1.152 88.528h-30.448zm8.064-72.912V368.4h21.232c34.544 0 36.08-57.312 0-57.312H204.72zM303.872 312.112v20.336h32.624c4.608 0 9.216 4.608 9.216 9.072 0 4.224-4.608 7.68-9.216 7.68h-32.624v26.864c0 4.48-3.184 7.92-7.664 7.92-5.632 0-9.072-3.44-9.072-7.92v-72.672c0-4.592 3.456-7.936 9.072-7.936h44.912c5.632 0 8.96 3.344 8.96 7.936 0 4.096-3.328 8.704-8.96 8.704h-37.248v.016z" />
                                                </g>
                                                <path d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z" fill="#cad1d8" />
                                            </svg>
                                        </span>
                                    ) : (
                                        <img
                                            style={{
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                            height="50"
                                            width="50"
                                            className="uploaded-image"
                                            key={index}
                                            src={file.src}
                                            alt={file.src.split("/").pop()}
                                            onClick={() => openReference(file)}
                                        />
                                    );
                                })}

                                <FieldArray
                                    name={`${section}.questions`}
                                    classes={classes}
                                    propertyId={propertyId}
                                    hasSmartLock={hasSmartLock}
                                    change={change}
                                    parentIndex={index}
                                    t={t}
                                    component={renderQuestion}
                                />
                            </div>
                        )}
                    </div>
                );
            })
        ) : (
            <Typography variant="h6" color="inherit" className={classes.grow}>
                {t("checkout.NoQuestions")}
            </Typography>
        )}
    </div>
);

const renderQuestion = ({ fields, parentIndex, change, t, classes, propertyId, hasSmartLock, meta: { error } }) => (
    <div>
        {fields.map((question, index) => {
            let current = fields.get(index);

            let renderer = renderTextField;
            if (current["questionType"] === "boolean") {
                renderer = renderCheckbox;
            } else if (current["questionType"] === "file") {
                renderer = renderUploader;
            } else if (current["questionType"] === "yesno") {
                renderer = renderRadio;
            } else if (current["questionType"] === "unlock") {
                renderer = renderUnlock;
            }

            return (
                <div key={index}>
                    <Field
                        component={renderer}
                        type={current["questionType"]}
                        change={change}
                        parentIndex={parentIndex}
                        t={t}
                        classes={classes}
                        index={index}
                        propertyId={propertyId}
                        hasSmartLock={hasSmartLock}
                        name={`${question}.questionContent`}
                        required={Boolean(current["questionRequired"])}
                        label={current["questionTitle"]}
                    />
                    <br />
                </div>
            );
        })}
        {error && <span>{error}</span>}
    </div>
);

class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkout: [],
            isLoading: true,
            isDisabled: false,
            submitDisabled: false,
            activeStep: 0,
            totalSteps: 0,
            newMaintenanceTaskOpen: false,
            newMaintenanceTask: {
                taskDesc: "",
                taskPriority: 0,
                taskFiles: []
            },
            newTaskOpen: false,
            newTask: {
                taskDesc: "",
                taskFiles: []
            },
            referenceOpen: false,
            currentReference: null,
            accessLevel: 0,
            checkoutComplete: false,
            listOpen: false,
            notesList: {
                laundry: [],
                maintenance: [],
                company: []
            }
        };

        this.submitMaintenanceTask = this.submitMaintenanceTask.bind(this);
        this.submitTask = this.submitTask.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handleChangeMaintenanceTask = this.handleChangeMaintenanceTask.bind(this);
        this.handleChangeTask = this.handleChangeTask.bind(this);
        this.openReference = this.openReference.bind(this);

        var decoded = Auth.getProfile();

        this.state.accessLevel = decoded.accessLevel;
    }

    openReference(file) {
        if (file) {
            this.setState({ currentReference: file, referenceOpen: true });
        }
    }

    async componentDidMount() {
        var lastSave = JSON.parse(localStorage.getItem("colastSave"));

        if (lastSave && lastSave.property.propertyId === this.props.match.params.id) {
            this.setState(
                {
                    checkout: lastSave,
                    activeStep: lastSave["step"] ? lastSave["step"] : 0,
                    totalSteps: lastSave.sections.length - 1
                },
                () => {
                    this.props.load(lastSave);

                    this.setState({
                        isLoading: false
                    });
                    localStorage.removeItem("colastSave");
                }
            );
        } else {
            let checkout = await this.fetchCheckout();

            this.setState(
                {
                    checkout: checkout.checkout,
                    isLoading: false,
                    totalSteps: checkout.checkout.sections.length - 1
                },
                () => {
                    this.props.load(checkout.checkout);
                }
            );
        }
    }

    async fetchCheckout() {
        var self = this;

        return new Promise(function (resolve, reject) {
            fetchClient
                .get("/co/checkout/" + self.props.match.params.id)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchNotes() {
        var self = this;

        return new Promise(function (resolve, reject) {
            fetchClient
                .get("/co/notes/" + self.state.checkout.cleaningId)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    UNSAFE_componentWillMount() {
        this.maintenancetaskimageuploader = new Uppy({
            id: "maimage",
            autoProceed: true
        })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "ma",
                                upload_type: "ma",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.setState({
                    newMaintenanceTask: {
                        ...this.state.newMaintenanceTask,
                        taskFiles: [...this.state.newMaintenanceTask.taskFiles, resp.uploadURL]
                    }
                });
            });

        this.taskimageuploader = new Uppy({
            id: "taskimage",
            autoProceed: true
        })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "tasks",
                                upload_type: "task",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.setState({
                    newTask: {
                        ...this.state.newTask,
                        taskFiles: [...this.state.newTask.taskFiles, resp.uploadURL]
                    }
                });
            });
    }

    componentWillUnmount() {
        this.maintenancetaskimageuploader.close();
        this.taskimageuploader.close();
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleChangeTask = event => {
        this.setState({
            newTask: {
                ...this.state.newTask,
                [event.target.name]: event.target.value
            }
        });
    };

    handleChangeMaintenanceTask = event => {
        this.setState({
            newMaintenanceTask: {
                ...this.state.newMaintenanceTask,
                [event.target.name]: event.target.value
            }
        });
    };

    submitMaintenanceTask() {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("ma/new", {
                ...self.state.newMaintenanceTask,
                propertyId: self.state.checkout.property.propertyId
            })
            .then(function (response) {
                self.setState({
                    newMaintenanceTask: {
                        ...self.state.newMaintenanceTask,
                        taskPriority: 0,
                        taskDesc: "",
                        taskFiles: []
                    },
                    newMaintenanceTaskOpen: false,
                    isDisabled: false
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    submitTask() {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("tasks/new", {
                ...self.state.newTask,
                propertyId: self.state.checkout.property.propertyId
            })
            .then(function (response) {
                self.setState({
                    newTask: {
                        ...self.state.newTask,
                        taskDesc: "",
                        taskFiles: []
                    },
                    newTaskOpen: false,
                    isDisabled: false
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    saveChanges(values) {
        var self = this;

        if (self.state.activeStep === self.state.totalSteps) {
            self.setState({ submitDisabled: true });

            fetchClient
                .post("/co/checkout/new/" + self.state.checkout.property.propertyId, values)
                .then(function (response) {
                    localStorage.removeItem("colastSave");

                    self.setState({ checkoutComplete: true });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            values["step"] = self.state.activeStep + 1;
            localStorage.setItem("colastSave", JSON.stringify(values));
            self.setState({
                activeStep: self.state.activeStep + 1
            });
        }
    }

    handleMaintenanceOpen = () => {
        this.setState({ newMaintenanceTaskOpen: true });
    };

    handleMaintenanceClose = () => {
        this.setState({ newMaintenanceTaskOpen: false });
    };

    handleTaskOpen = () => {
        this.setState({ newTaskOpen: true });
    };

    handleTaskClose = () => {
        this.setState({ newTaskOpen: false });
    };

    handleOpenReference = () => {
        this.setState({ referenceOpen: true });
    };

    handleCloseReference = () => {
        this.setState({ referenceOpen: false });
    };

    handleListOpen = async () => {
        let notes = this.state.checkout.cleaningId ? await this.fetchNotes() : this.state.notesList;
        this.setState({ listOpen: true, notesList: notes });
    };

    handleCloseList = () => {
        this.setState({ listOpen: false });
    };

    render() {
        const { handleSubmit, submitting, classes, invalid, t, theme, change } = this.props;

        const { maintenancetaskimageuploader, taskimageuploader } = this;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading
                                ? t("titles.Checkout", {
                                      param: this.state.checkout.property.propertyName
                                  })
                                : t("titles.Loading") + "..."}
                        </Typography>
                        <IconButton onClick={this.handleMaintenanceOpen} className={classes.menuButton} color="inherit" aria-label={t("checkout.MaintenanceTask")}>
                            <Report />
                        </IconButton>

                        {this.state.accessLevel > 3 && (
                            <>
                                <IconButton onClick={this.handleTaskOpen} className={classes.menuButton} color="inherit" aria-label={t("checkout.Task")}>
                                    <Task />
                                </IconButton>

                                <IconButton onClick={this.handleListOpen} className={classes.menuButton} color="inherit" aria-label={t("checkout.NotesList")}>
                                    <List />
                                </IconButton>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <Paper className={classes.paper}>
                            {!this.state.checkoutComplete ? (
                                <>
                                    <form style={{ padding: 10 }} onSubmit={handleSubmit(val => this.saveChanges(val))}>
                                        <FieldArray
                                            name="sections"
                                            change={change}
                                            classes={classes}
                                            openReference={this.openReference}
                                            component={renderSections}
                                            hasSmartLock={this.state.checkout.property.hasSmartLock}
                                            propertyId={this.state.checkout.property.propertyId}
                                            t={t}
                                            activeStep={this.state.activeStep}
                                        />
                                        <br />

                                        <MobileStepper
                                            variant="progress"
                                            steps={this.state.totalSteps + 1}
                                            position="bottom"
                                            activeStep={this.state.activeStep}
                                            nextButton={
                                                <Button type="submit" size="small" disabled={invalid || submitting || this.state.submitDisabled}>
                                                    {this.state.activeStep === this.state.totalSteps ? t("checkout.Submit") : t("checkout.Continue")}

                                                    {this.state.activeStep !== this.state.totalSteps && <>{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</>}

                                                    {this.state.submitDisabled && <CircularProgress size={24} className={classes.buttonSaving} />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                                                    {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                    {t("checkout.Back")}
                                                </Button>
                                            }
                                        />
                                    </form>
                                </>
                            ) : (
                                <div style={{ padding: 10 }}>
                                    <Typography variant="h6" color="inherit" className={classes.grow}>
                                        {t("checkout.CheckoutCompleted")}
                                    </Typography>
                                    <Button variant="contained" color="primary" href="https://cleaningapp.stay-u-nique.com">
                                        {t("checkout.ReturnToCleaningApp")}
                                    </Button>
                                </div>
                            )}
                        </Paper>
                    ) : null}
                </div>
                <Dialog fullScreen open={this.state.newMaintenanceTaskOpen} onClose={this.handleMaintenanceClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" className={classes.menuButton} onClick={this.handleMaintenanceClose} aria-label={t("misc.Close")}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("checkout.NewMaintenanceTask")}
                            </Typography>
                            <Button color="inherit" disabled={!this.state.newMaintenanceTask.taskDesc || this.state.isDisabled} onClick={this.submitMaintenanceTask}>
                                {t("checkout.SubmitMaintenanceTask")}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={t("checkout.MaintenanceTaskLabel")}
                                multiline
                                onChange={this.handleChangeMaintenanceTask}
                                name="taskDesc"
                                value={this.state.newMaintenanceTask.taskDesc}
                                error={!Boolean(this.state.newMaintenanceTask.taskDesc)}
                            />
                        </FormControl>
                        <br /> <br />
                        <FormControl required fullWidth>
                            <InputLabel htmlFor="taskPriority">{t("checkout.MaintenanceTaskPriority")}</InputLabel>
                            <Select
                                native
                                value={this.state.newMaintenanceTask.taskPriority}
                                onChange={this.handleChangeMaintenanceTask}
                                inputProps={{
                                    name: "taskPriority",
                                    id: "taskPriority"
                                }}
                            >
                                <option value={0}>{t("checkout.MaintenanceTaskPriorityLow")}</option>
                                <option value={1}>{t("checkout.MaintenanceTaskPriorityMedium")}</option>
                                <option value={2}>{t("checkout.MaintenanceTaskPriorityHigh")}</option>
                            </Select>
                        </FormControl>
                        <DragDrop
                            uppy={maintenancetaskimageuploader}
                            locale={{
                                strings: {
                                    dropHereOr: t("checkout.Upload") + " %{browse}",
                                    browse: t("checkout.Browse")
                                }
                            }}
                        />
                        <StatusBar uppy={maintenancetaskimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
                        {this.state.newMaintenanceTask.taskFiles.map((img, index) => {
                            return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                        })}
                    </div>
                </Dialog>

                <Dialog fullScreen open={this.state.newTaskOpen} onClose={this.handleTaskClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" className={classes.menuButton} onClick={this.handleTaskClose} aria-label={t("misc.Close")}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("checkout.NewTask")}
                            </Typography>
                            <Button color="inherit" disabled={!this.state.newTask.taskDesc || this.state.isDisabled} onClick={this.submitTask}>
                                {t("checkout.SubmitTask")}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={t("checkout.TaskLabel")}
                                multiline
                                onChange={this.handleChangeTask}
                                name="taskDesc"
                                value={this.state.newTask.taskDesc}
                                error={!Boolean(this.state.newTask.taskDesc)}
                            />
                        </FormControl>

                        <DragDrop
                            uppy={taskimageuploader}
                            locale={{
                                strings: {
                                    dropHereOr: t("checkout.Upload") + " %{browse}",
                                    browse: t("checkout.Browse")
                                }
                            }}
                        />
                        <StatusBar uppy={taskimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
                        {this.state.newTask.taskFiles.map((img, index) => {
                            return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                        })}
                    </div>
                </Dialog>

                <Dialog fullScreen open={this.state.listOpen} onClose={this.handleCloseList} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" className={classes.menuButton} onClick={this.handleCloseList} aria-label={t("misc.Close")}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("titles.NotesList")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <Typography variant="h6">{t("checkout.CompanyNotes")}</Typography>

                        {this.state.notesList.company && this.state.notesList.company.length > 0 ? (
                            this.state.notesList.company.map((note, index) => {
                                return (
                                    <div key={index}>
                                        <p key={note.noteId}>
                                            {moment(note.dateCreated, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {note.noteContent}
                                        </p>
                                        {note.images.map((image, index) => {
                                            return (
                                                <img
                                                    style={{
                                                        paddingLeft: 2,
                                                        paddingRight: 2
                                                    }}
                                                    height="50"
                                                    width="50"
                                                    className="uploaded-image"
                                                    key={index}
                                                    src={image}
                                                    alt={image.split("/").pop()}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <p> {t("checkout.NoNotes")}</p>
                        )}

                        <Typography variant="h6">{t("checkout.LaundryNotes")}</Typography>
                        {this.state.notesList.laundry && this.state.notesList.laundry.length > 0 ? (
                            this.state.notesList.laundry.map((note, index) => {
                                return (
                                    <div key={index}>
                                        <p key={note.noteId}>
                                            {moment(note.dateCreated, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {note.noteContent}
                                        </p>
                                        {note.images.map((image, index) => {
                                            return (
                                                <img
                                                    style={{
                                                        paddingLeft: 2,
                                                        paddingRight: 2
                                                    }}
                                                    height="50"
                                                    width="50"
                                                    className="uploaded-image"
                                                    key={index}
                                                    src={image}
                                                    alt={image.split("/").pop()}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <p> {t("checkout.NoNotes")}</p>
                        )}

                        <Typography variant="h6">{t("checkout.MaintenanceTasks")}</Typography>

                        {this.state.notesList.maintenance && this.state.notesList.maintenance.length > 0 ? (
                            this.state.notesList.maintenance.map((note, index) => {
                                return (
                                    <div key={index}>
                                        <p key={note.noteId}>
                                            {moment(note.dateCreated, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm")} {note.noteContent}
                                        </p>
                                        {note.images.map((image, index) => {
                                            return (
                                                <img
                                                    style={{
                                                        paddingLeft: 2,
                                                        paddingRight: 2
                                                    }}
                                                    height="50"
                                                    width="50"
                                                    className="uploaded-image"
                                                    key={index}
                                                    src={image}
                                                    alt={image.split("/").pop()}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <p> {t("checkout.NoTasks")}</p>
                        )}
                    </div>
                </Dialog>

                {this.state.currentReference && (
                    <Dialog fullScreen open={this.state.referenceOpen} onClose={this.handleCloseReference} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={this.handleCloseReference} aria-label={t("misc.Close")}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    {t("checkout.Reference")}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div
                            style={{
                                paddingTop: 15,
                                paddingLeft: 15,
                                paddingRight: 15
                            }}
                        >
                            {this.state.currentReference.type === "pdf" ? (
                                <object style={{ width: "100%", height: "600px" }} data={this.state.currentReference.src} type="application/pdf">
                                    <embed src={this.state.currentReference.src} type="application/pdf" />
                                </object>
                            ) : (
                                <img
                                    style={{
                                        width: "100%",
                                        height: "auto"
                                    }}
                                    src={this.state.currentReference.src}
                                    alt={this.state.currentReference.src.split("/").pop()}
                                />
                            )}
                        </div>
                    </Dialog>
                )}
            </div>
        );
    }
}

Checkout = reduxForm({
    form: "checkoutform",
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
})(Checkout);

const selector = formValueSelector("checkoutform");

Checkout = connect(
    (state, props) => ({
        initialValues: state.checkout.data,
        sections: selector(state, "sections")
    }),
    { load: loadCheckout, change }
)(Checkout);

Checkout.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles, { withTheme: true })(Checkout)));

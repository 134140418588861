import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import checkout from "../reducers/checkout";

const rootReducer = combineReducers({
    checkout,
    form: formReducer
});

export default rootReducer;

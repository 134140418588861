import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reducer from "./components/store/reducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

const store = createStore(reducer);

setDefaultTranslations({ es, en });
setDefaultLanguage("es");

var coSettings = JSON.parse(localStorage.getItem("coSettings"));
if (coSettings) {
    if (coSettings.language) {
        setLanguage(coSettings.language);
    }
} else {
    localStorage.setItem("coSettings", JSON.stringify({ language: "es" }));
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
                ref={notistackRef}
                action={key => (
                    <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
                        <CloseIcon />
                    </IconButton>
                )}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <App />
            </SnackbarProvider>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById("root")
);

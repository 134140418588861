import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

import { withTranslation, setLanguage, getLanguage } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingTop: theme.spacing()
    },
    button: {
        margin: theme.spacing()
    }
});

class Settings extends React.Component {
    constructor() {
        super();

        this.state = {
            currentLang: getLanguage()
        };
    }

    changeLang(lang) {
        this.setState({ currentLang: lang });
        localStorage.setItem("coSettings", JSON.stringify({ language: lang }));
        setLanguage(lang);
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {t("titles.Settings")}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.content}>
                    <Button color={this.state.currentLang === "en" ? "primary" : "default"} onClick={() => this.changeLang("en")} variant="contained" className={classes.button}>
                        {t("languages.English")}
                    </Button>
                    <Button color={this.state.currentLang === "es" ? "primary" : "default"} onClick={() => this.changeLang("es")} variant="contained" className={classes.button}>
                        {t("languages.Spanish")}
                    </Button>
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(Settings)));

const validate = values => {
    const errors = {};

    if (!values.sections || !values.sections.length) {
        errors.sections = { _error: "At least one section must be entered" };
    } else {
        const sectionsArrayErrors = [];
        values.sections.forEach((section, sectionIndex) => {
            const sectionErrors = {};
            if (section && section.questions && section.questions.length) {
                const questionArrayErrors = [];

                section.questions.forEach((question, questionIndex) => {
                    if (!question || !question.questionContent) {
                        if (question.questionRequired) {
                            questionArrayErrors[questionIndex] = {
                                questionContent: "Required"
                            };
                        }
                    }
                });
                if (questionArrayErrors.length) {
                    sectionErrors.questions = questionArrayErrors;
                    sectionsArrayErrors[sectionIndex] = sectionErrors;
                }
            }
        });
        if (sectionsArrayErrors.length) {
            errors.sections = sectionsArrayErrors;
        }
    }

    return errors;
};

export default validate;

import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CheckoutsList from "./components/CheckoutsList";
import Login from "./components/Login";
import Settings from "./components/Settings";
import Checkout from "./components/Checkout";

const theme = createMuiTheme({
    palette: {
        primary: { main: "#2196f3", contrastText: "#ffffff" },
        secondary: { main: "#00b0ff", contrastText: "#000000" }
    },
    typography: {
        useNextVariants: true
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={CheckoutsList} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/checkout/:id" component={Checkout} />
                        <Redirect to="/" />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
